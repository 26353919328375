import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "admins.list",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminList")),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminAdd")),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/admin/admin-edit")
    ),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "users.list",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/apps/user/userList")),
  },
  {
    key: "users.add",
    path: `${APP_PREFIX_PATH}/user/add`,
    component: React.lazy(() => import("views/app-views/apps/user/userAdd")),
  },
  {
    key: "users.edit",
    path: `${APP_PREFIX_PATH}/user/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/user/userEdit")),
  },
  {
    key: "users.details",
    path: `${APP_PREFIX_PATH}/user/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/UserDetails")
    ),
  },
  {
    key: "company.category",
    path: `${APP_PREFIX_PATH}/company-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/category/categoryList")
    ),
  },
  {
    key: "company-category.add-company-category",
    path: `${APP_PREFIX_PATH}/company-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/category/add-categoryList")
    ),
  },
  {
    key: "company-category.edit-company-category",
    path: `${APP_PREFIX_PATH}/company-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/category/edit-categoryList")
    ),
  },
  {
    key: "company.list",
    path: `${APP_PREFIX_PATH}/company/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/editCompany")
    ),
  },

  {
    key: "apps.project",
    path: `${APP_PREFIX_PATH}/apps/project`,
    component: React.lazy(() => import("views/app-views/apps/project")),
  },
  {
    key: "apps.project.list",
    path: `${APP_PREFIX_PATH}/apps/project/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/project/project-list/ProjectList")
    ),
  },
  {
    key: "apps.project.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
    component: React.lazy(() =>
      import("views/app-views/apps/project/scrumboard")
    ),
  },
  {
    key: "apps.organizations",
    path: `${APP_PREFIX_PATH}/apps/organizations`,
    component: React.lazy(() => import("views/app-views/apps/organizations")),
  },
  {
    key: "apps.organizations.add-organization",
    path: `${APP_PREFIX_PATH}/apps/organizations/add-organization`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/add-organization")
    ),
  },
  {
    key: "apps.organizations.edit-organization",
    path: `${APP_PREFIX_PATH}/apps/organizations/edit-organization/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/edit-organization")
    ),
  },
  {
    key: "apps.organizations.organization-list",
    path: `${APP_PREFIX_PATH}/apps/organizations/organization-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/organization-list")
    ),
  },
  {
    key: "apps.organizations.orders",
    path: `${APP_PREFIX_PATH}/apps/organizations/orders`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/orders")
    ),
  },

  {
    key: "apps.assteTags",
    path: `${APP_PREFIX_PATH}/apps/assetTag`,
    component: React.lazy(() =>
      import("views/app-views/apps/assetTag/assetTag-list")
    ),
  },
  {
    key: "apps.assetTag.assetTag-add",
    path: `${APP_PREFIX_PATH}/apps/assetTag/assetTag-add`,
    component: React.lazy(() =>
      import("views/app-views/apps/assetTag/assetTag-add")
    ),
  },
  {
    key: "apps.assetTag.assetTag-edit",
    path: `${APP_PREFIX_PATH}/apps/assetTag/assetTag-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/assetTag/assetTag-edit")
    ),
  },
  {
    key: "apps.assetTag.assetTag-list",
    path: `${APP_PREFIX_PATH}/apps/assetTag/assetTag-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/assetTag/assetTag-list")
    ),
  },
  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import("views/app-views/pages")),
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile")),
  },
  {
    key: "pages.invoice",
    path: `${APP_PREFIX_PATH}/pages/invoice`,
    component: React.lazy(() => import("views/app-views/pages/invoice")),
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
  },

  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/apps/dashboard/view-dashboard/")
    ),
  },


    //specializations
    {
      key: "specializationstree.user-specializations",
      path: `${APP_PREFIX_PATH}/user-specializations/list`,
      component: React.lazy(() =>
        import(
          "views/app-views/apps/specialization/user-Specialization/Specialization-list"
        )
      ),
    },
    {
      key: "specializationstree.user-specializations",
      path: `${APP_PREFIX_PATH}/user-specialization/add`,
      component: React.lazy(() =>
        import(
          "views/app-views/apps/specialization/user-Specialization/Specialization-Add"
        )
      ),
    },
    {
      key: "specializationstree.user-specializations",
      path: `${APP_PREFIX_PATH}/user-specialization/edit/:id`,
      component: React.lazy(() =>
        import(
          "views/app-views/apps/specialization/user-Specialization/Specialization-Edit"
        )
      ),
    },
  
  // Post section
  {
    key: "post-category",
    path: `${APP_PREFIX_PATH}/post-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/categoryList")
    ),
  },
  {
    key: "post-category.add-post-category",
    path: `${APP_PREFIX_PATH}/post-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/add-categoryList")
    ),
  },
  {
    key: "post-category.edit-post-category",
    path: `${APP_PREFIX_PATH}/post-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/category/edit-categoryList")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/PostList")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/Add-Post")
    ),
  },
  {
    key: "post-category.postedit",
    path: `${APP_PREFIX_PATH}/posts/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/Edit-post")
    ),
  },
  {
    key: "post-category.postlist",
    path: `${APP_PREFIX_PATH}/posts/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/Posts/PostDetails")
    ),
  },
  // Post comments
  {
    key: "post-category.commentlist",
    path: `${APP_PREFIX_PATH}/comments/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/post/postComments/PostCommentList")
    ),
  },

  //Tags
  // {
  //   key: "post-category.postlist",
  //   path: `${APP_PREFIX_PATH}/tags/list`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/post/Tags/TagList")
  //   ),
  // },
    // Site Setting
    {
      key: "site.settings.list",
      path: `${APP_PREFIX_PATH}/site-settings`,
      component: React.lazy(() =>
        import("views/app-views/pages/setting/SiteSetting")
      ),
    },

  // job category
  {
    key: "job-category.category",
    path: `${APP_PREFIX_PATH}/job-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/JobList")
    ),
  },
  {
    key: "job-category.app-category",
    path: `${APP_PREFIX_PATH}/job-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/Add-job-category")
    ),
  },
  {
    key: "job-category.app-category",
    path: `${APP_PREFIX_PATH}/job-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobCategory/Edit-job-category")
    ),
  },

  // jobtypes

  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job-types/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/JobType/job-type-list")
    ),
  },
  // Jobs
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/JobmainList")
    ),
  },
  {
    key: "job-category.add-job",
    path: `${APP_PREFIX_PATH}/job/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/add-job")
    ),
  },
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/edit-job")
    ),
  },

  {
    key: "job-category.event",
    path: `${APP_PREFIX_PATH}/job/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Job/jobDetails")
    ),
  },


  // Job Application
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/applications/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/Applications/List")
    ),
  },
  {
    key: "job-category.job",
    path: `${APP_PREFIX_PATH}/job/application/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/JobField/ApplicationDetails")
    ),
  },
  // Event...
  {
    key: "event-category.category-list",
    path: `${APP_PREFIX_PATH}/event-category/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/category/categoryList")
    ),
  },
  {
    key: "event-category.add-category",
    path: `${APP_PREFIX_PATH}/event-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/category/addCategoryList")
    ),
  },
  {
    key: "event-category.edit-category",
    path: `${APP_PREFIX_PATH}/event-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/category/editCategoryList")
    ),
  },
  // event
  {
    key: "event-category.event",
    path: `${APP_PREFIX_PATH}/event/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/Event/event-list")
    ),
  },
  {
    key: "event-category.add-event",
    path: `${APP_PREFIX_PATH}/event/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/Event/Add-Event")
    ),
  },
  {
    key: "event-category.edit-event",
    path: `${APP_PREFIX_PATH}/event/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/Event/Edit-Event")
    ),
  },
  {
    key: "event-category.event",
    path: `${APP_PREFIX_PATH}/event/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/Event/EventDetails")
    ),
  },
  // tags
  {
    key: "event-category.tags",
    path: `${APP_PREFIX_PATH}/tags/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/EventsField/Tags/TagsList")
    ),
  },

  //classified
  {
    key: "classified-cgategory.category",
    path: `${APP_PREFIX_PATH}/classified-category/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/Classified/category/addCategory")
    ),
  },
  {
    key: "classified-cgategory.category",
    path: `${APP_PREFIX_PATH}/classified-category/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/Classified/category/editCategory")
    ),
  },
  {
    key: "classified-cgategory.category",
    path: `${APP_PREFIX_PATH}/classified-categories/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/Classified/category/listCategory")
    ),
  },
  // Pages
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesList")),
  },
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/page/add`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesAdd")),
  },
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/page/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesEdit")),
  },
  //Email TEmplates

  {
    key: "EmailTemplate.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateList")
    ),
  },
  {
    key: "EmailTemplate.add",
    path: `${APP_PREFIX_PATH}/email-template/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateAdd")
    ),
  },
  {
    key: "EmailTemplate.edit",
    path: `${APP_PREFIX_PATH}/email-template/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/Email-Templates/EmailTemplateEdit")
    ),
  },
  //Products
  {
    key: "Product.list",
    path: `${APP_PREFIX_PATH}/products/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/ProductField/ProductLIst")
    ),
  },
  {
    key: "Product.add",
    path: `${APP_PREFIX_PATH}/product/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/ProductField/ProductAdd")
    ),
  },
  {
    key: "Product.edit",
    path: `${APP_PREFIX_PATH}/product/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/ProductField/ProductEdit")
    ),
  },
  {
    key: "Product.Details",
    path: `${APP_PREFIX_PATH}/product/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/ProductField/productDetails")
    ),
  },

  // Plans=====>>>
  {
    key: "Plans.list",
    path: `${APP_PREFIX_PATH}/plans/list`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-list")),
  },
  {
    key: "Plans.add",
    path: `${APP_PREFIX_PATH}/plans/add`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-add")),
  },
  {
    key: "Plans.edit",
    path: `${APP_PREFIX_PATH}/plans/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/Plans/Plan-edit")),
  },

  //Activity Log List
  {
    key: "Activity.list",
    path: `${APP_PREFIX_PATH}/activityLog/list`,
    component: React.lazy(() => import("views/app-views/apps/ActivityLog/ActivityLogList")),
  },
];
